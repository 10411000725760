import { Component } from "react"
import PropTypes from "prop-types"
import SubServiceCard from "shared/components/service_card"
import { serviceShape } from "app/lib/shared_prop_types"

class ServiceCard extends Component {
  render() {
    return (
      <li className="service-card-list-item">
        <SubServiceCard service={this.props.service} path={`/${this.props.service.module_name}`} />
      </li>
    )
  }
}

ServiceCard.propTypes = {
  service: serviceShape.isRequired,
  position: PropTypes.number.isRequired,
  fieldPosition: PropTypes.number,
}

export default ServiceCard
