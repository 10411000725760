import Header from "./header"

import "./upsell.scss"

export interface TrialConfigUrls {
  proSubscriptionsUrl: string
  proPlusSubscriptionsUrl: string
  holidayPromoSubscriptionsUrl: string
}

export interface TrialConfigAssets {
  proIllustration: string
  proStudentIllustration: string
  eo1Illustration: string
  smartHomeIllustration: string
  holidayPromoIllustration: string
}
interface Props {
  goal: string
  holidayPromo: boolean
  onSkip: () => void
  onClickBack: () => void
  onClickContinue: (evt: MouseEvent) => void
  skipHeader: boolean
  trialConfig: {
    assets: TrialConfigAssets
    urls: TrialConfigUrls
  }
  userIsStudent: boolean
}

const TITLES = {
  holiday: "Get 24% off",
  eo1: "Our best business tools",
  smart_home: "Top smart home tools",
  student: "Pro+ student discount",
  default: "Start your trial",
}

const BODIES = {
  holiday:
    "For a limited time, get 24% off Pro! Make your favorite things work better together, with complete access to our most popular features and services.",
  eo1: "",
  smart_home: "Make the devices in your home work better together.",
  student:
    "Save 90% on your subscription and get full access to popular Applets and features. Make your favorite things work better together.",
  default:
    "Subscribe to get full access to popular Applets and features. Make your favorite things work better together.",
}

const PERKS = {
  holiday: [
    "Use multiple actions.",
    "Access to Twitter and Webhooks.",
    "Create up to 20 Applets.",
    "Your first 7 days are free!",
  ],
  eo1: [
    "Enhance your workflows with AI.",
    "Use multiple actions, queries, and filter code.",
    "Create unlimited Applets.",
    "Your first 7 days are free!",
  ],
  smart_home: [
    "Use multiple actions.",
    "Access exclusive Pro services.",
    "Create up to 20 Applets.",
    "Your first 7 days are free!",
  ],
  student: [
    "Use multiple actions.",
    "Access exclusive Pro services.",
    "Create up to 20 Applets.",
    "Your first 7 days are free!",
  ],
  default: [
    "Use multiple actions.",
    "Access exclusive Pro services.",
    "Create up to 20 Applets.",
    "Your first 7 days are free!",
  ],
}

const BADGE_TIERS = {
  default: "pro",
  eo1: "pro_plus",
  holiday: "pro",
  smart_home: "pro",
  student: "pro_plus",
}

const UPGRADE_URLS = {
  pro: "proSubscriptionsUrl",
  pro_plus: "proPlusSubscriptionsUrl",
}

const Upsell = ({
  goal,
  holidayPromo,
  onSkip,
  trialConfig,
  onClickBack,
  onClickContinue,
  skipHeader,
  userIsStudent,
}: Props) => {
  let userType = goal
  if (userIsStudent) {
    userType = "student" // student discount has priority
  } else   if (holidayPromo) {
    userType = "holiday"
  }

  const illustrations = {
    holiday: {
      url: trialConfig.assets.holidayPromoIllustration,
      alt: "",
    },
    eo1: {
      url: trialConfig.assets.eo1Illustration,
      alt: "",
    },
    smart_home: {
      url: trialConfig.assets.smartHomeIllustration,
      alt: "",
    },
    student: {
      url: trialConfig.assets.proStudentIllustration,
      alt: "Subscribe to get full access",
    },
    default: {
      url: trialConfig.assets.proIllustration,
      alt: "Try Pro for Free",
    },
  }

  const title = TITLES[userType] || TITLES.default
  let body = BODIES[userType]
  if (body === undefined) {
    body = BODIES.default
  }
  const illustrationUrl = illustrations[userType]?.url || illustrations.default.url
  const illustrationAlt = illustrations[userType]?.alt || illustrations.default.alt
  const perks = PERKS[userType] || PERKS.default
  const badgeTier = BADGE_TIERS[userType] || BADGE_TIERS.default
  let url = trialConfig.urls[UPGRADE_URLS[badgeTier]] || trialConfig.urls.proSubscriptionsUrl

  // If user is student, use plain url. SubscriptionController is responsible to do another check by student and fill the promo code.
  if (holidayPromo && !userIsStudent) {
    url = trialConfig.urls.holidayPromoSubscriptionsUrl
  }

  return (
    <>
      {!skipHeader && <Header badgeTier={badgeTier} title="" onClickBack={onClickBack} page="trial" />}
      <div styleName="container">
        <section styleName="welcome">
          <img src={illustrationUrl} alt={illustrationAlt} />
          <h1>{title}</h1>
          {!!body && <p styleName={body.length && perks.length ? "with-spacer" : ""}>{body}</p>}
          {!!perks.length && (
            <ul styleName="perks">
              {perks.map((perk: string, i: number) => (
                <li key={i}>{perk}</li>
              ))}
            </ul>
          )}
        </section>

        <div className="flexbox">
          <section styleName="action-buttons-container" className="action-buttons-container">
            <a
              href={url}
              onClick={onClickContinue}
              role="button"
              className="button-primary pay-later"
              data-dd-action-name="NUI Trial Continue"
            >
              {userIsStudent ? "Claim deal" : "Continue"}
            </a>

            <a onClick={onSkip} styleName="skip-pro-link" data-dd-action-name="NUI Trial Not now">
              Not now
            </a>
          </section>
        </div>
      </div>
    </>
  )
}

export default Upsell
