import { gql, graphQueryBang } from "shared/lib/graphql"

const SendPinToMobileActivateChannels = gql`
  mutation SendPinToMobileActivateChannels(
    $channel_module_name: String!
    $email_address: String
    $phone_number: String
  ) {
    sendPinToMobileActivateChannels(
      input: { channel_module_name: $channel_module_name, email_address: $email_address, phone_number: $phone_number }
    ) {
      errors {
        attribute
        message
      }
      status
    }
  }
`

const NewPinActivateLiveChannel = gql`
  mutation NewPinActivateLiveChannel(
    $channel_module_name: String!
    $email_address: String
    $phone_number: String
    $pin: String!
  ) {
    newPinActivateLiveChannel(
      input: {
        channel_module_name: $channel_module_name
        email_address: $email_address
        phone_number: $phone_number
        pin: $pin
      }
    ) {
      errors {
        attribute
        message
      }
      status
    }
  }
`

const NewTokenActivateLiveChannel = gql`
  mutation LiveChannelCreateMutation(
    $channel_module_name: String!
    $api_token: String
    $replace_existing_connection: Boolean
  ) {
    liveChannelCreate(
      input: {
        channel_module_name: $channel_module_name
        api_token: $api_token
        replace_existing_connection: $replace_existing_connection
      }
    ) {
      errors {
        attribute
        message
      }
      live_channel {
        id
        dynamic_channel_fields
      }
    }
  }
`

const ActivateAuthNotRequiredLiveChannel = gql`
  mutation LiveChannelCreateAuthNotRequired(
    $channel_module_name: String!
    $custom_channel_fields: JSON
    $live_channel_id: ID
  ) {
    liveChannelCreateAuthNotRequired(
      input: {
        channel_module_name: $channel_module_name
        custom_channel_fields: $custom_channel_fields
        live_channel_id: $live_channel_id
      }
    ) {
      activated
      live_channel {
        id
      }
      errors {
        attribute
        message
      }
    }
  }
`

interface Attrs {
  channel_module_name: string
  phone_number?: string
  email_address?: string
  pin?: string
  api_token?: string
  replace_existing_connection?: boolean
}

interface ActivateAuthNotRequiredLiveChannelAttrs {
  channel_module_name: string
  custom_channel_fields?: { [key: string]: string }
  live_channel_id?: number
}

const sendPin = async (moduleName: string, identifier: string) => {
  const attrs: Attrs = { channel_module_name: moduleName }
  if (["sms", "phone_call"].includes(moduleName)) {
    attrs.phone_number = identifier
  } else {
    attrs.email_address = identifier
  }

  return await graphQueryBang(SendPinToMobileActivateChannels, attrs)
}

const activateWithPin = async (moduleName: string, identifier: string, pin: string) => {
  const attrs: Attrs = { channel_module_name: moduleName, pin }
  if (["sms", "phone_call"].includes(moduleName)) {
    attrs.phone_number = identifier
  } else {
    attrs.email_address = identifier
  }

  return await graphQueryBang(NewPinActivateLiveChannel, attrs)
}

const activateWithToken = async (moduleName: string, token: string, replace_existing_connection: boolean = false) => {
  const attrs: Attrs = {
    channel_module_name: moduleName,
    api_token: token,
    replace_existing_connection,
  }

  return await graphQueryBang(NewTokenActivateLiveChannel, attrs)
}

const activateAuthNotRequired = async (customFields: { [key: string]: string }, liveChannelId: number) => {
  const attrs: ActivateAuthNotRequiredLiveChannelAttrs = {
    channel_module_name: "wordpress",
    custom_channel_fields: customFields,
    live_channel_id: liveChannelId,
  }

  return await graphQueryBang(ActivateAuthNotRequiredLiveChannel, attrs).then(
    data => data.liveChannelCreateAuthNotRequired
  )
}

export { sendPin, activateWithPin, activateWithToken, activateAuthNotRequired }
