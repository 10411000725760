import React, { ChangeEvent, FormEvent, MouseEvent, useState } from "react"
import classnames from "classnames"
import parse from "html-react-parser"
import { GraphError } from "types"
import { activateAuthNotRequired } from "./api"

import ErrorIcon from "images/icons/icon-error-triangle.svg"

import "./activation.scss"

interface Props {
  liveChannelId?: string
  liveChannelFields?: {
    [key: string]: string
  }
  successUrl: string
}

const ActivateWordpress = ({ liveChannelId, liveChannelFields, successUrl }: Props) => {
  const [url, setUrl] = useState<string>(liveChannelFields?.url || "")
  const [username, setUsername] = useState<string>(liveChannelFields?.username || "")
  const [password, setPassword] = useState<string>("")
  const [error, setError] = useState<string>()
  const [submitDisabled, setSubmitDisabled] = useState<boolean>(true)

  const makeChangeHandler =
    (setter: React.Dispatch<React.SetStateAction<string>>) => (e: ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value
      setter(value)
      setSubmitDisabled(false)
    }

  const fields = [
    {
      name: "url",
      label: "Blog URL",
      type: "text",
      value: url,
      changeHandler: makeChangeHandler(setUrl),
    },
    {
      name: "username",
      label: "Username",
      type: "text",
      value: username,
      changeHandler: makeChangeHandler(setUsername),
    },
    {
      name: "password",
      label: "Password",
      type: "password",
      value: password,
      changeHandler: makeChangeHandler(setPassword),
    },
  ]

  const handleSubmit = async (e: FormEvent | MouseEvent) => {
    e.preventDefault()
    setSubmitDisabled(true)
    setError(undefined)

    try {
      const response = await activateAuthNotRequired({ url, username, password }, Number(liveChannelId))
      if (response?.activated) {
        window.location.assign(successUrl)
      } else if (response?.errors?.length) {
        setError(response.errors[0].message)
      } else {
        setError("Error validating your WordPress credentials")
      }
    } catch (e) {
      console.error(e)
      setError((e as GraphError).details || "Error validating your WordPress credentials")
    }
  }

  const twoStepAuthDisableUrl =
    "https://wordpress.com/support/security/two-step-authentication/#disable-two-step-authentication"
  const wordpressHelpArticleUrl =
    "https://help.ifttt.com/hc/en-us/articles/115010396748-The-WordPress-service-on-IFTTT-isn-t-working-correctly-for-me"

  return (
    <div styleName="activation">
      <div styleName="service-fields">
        <h1 role="heading">{liveChannelId ? "Reconnect" : "Connect"} to WordPress</h1>
        <div styleName="service-activation-form">
          <form className="new_live_channel" id="new_live_channel" onSubmit={handleSubmit}>
            <div styleName="live-channel-fields">
              <p>
                Hosted WordPress and WordPress.com blogs will both work on IFTTT. If you have two-step authentication
                enabled you will need to{" "}
                <a href={twoStepAuthDisableUrl} target="_blank" rel="noreferrer">
                  disable it
                </a>
                . If you are having issues with your WordPress blog, please reference our{" "}
                <a href={wordpressHelpArticleUrl} target="_blank" rel="noreferrer">
                  Help Center article
                </a>
                .
              </p>
              {fields.map(({ name, label, type, value, changeHandler }, index: number) => (
                <div key={index} styleName={classnames("activation-field-entry", { error: !!error })}>
                  <label htmlFor={name}>{label}</label>
                  <input
                    type={type}
                    autoFocus={index === 0}
                    name={name}
                    id={name}
                    value={value}
                    onChange={changeHandler}
                    autoComplete="off"
                  />
                </div>
              ))}
              {error && (
                <div styleName="after-message submit" role="alert">
                  <span>{parse(ErrorIcon)}</span>
                  {error}
                </div>
              )}
              <div styleName="send-pin">
                <button className="button-secondary" onClick={handleSubmit} disabled={!!submitDisabled}>
                  {liveChannelId ? "Update" : "Connect"}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default ActivateWordpress
