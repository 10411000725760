import { useRef } from "react"

interface Props {
  emailAssetUrl: string
  facebookAssetUrl: string
  illustrationAssetUrl: string
  referralCodePath: string
  twitterAssetUrl: string
}

const ReferralCode = (props: Props) => {
  const referralCodePathEncoded = encodeURIComponent(props.referralCodePath)
  const referralBodyEncoded = `${encodeURI(
    "Join IFTTT. Get 10% off when you sign up with my link:"
  )} ${referralCodePathEncoded}`
  const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${referralCodePathEncoded}&amp;src=sdkpreparse`
  const twitterShareUrl = `https://twitter.com/intent/tweet?text=${referralBodyEncoded}`
  const emailUrl = `mailto:?subject=Join IFTTT&body=${referralBodyEncoded}`

  const referralCodeInput = useRef()

  const onReferralCodeClick = e => {
    e.target.select()
  }

  const onEmailClick = () => {
    // nothing to do here
  }

  const onTwitterClick = e => {
    e.preventDefault()

    window.open(
      twitterShareUrl,
      "Join IFTTT",
      "scrollbars=yes,resizable=yes,toolbar=no,location=yes,width=550,height=420"
    )
  }

  const onFacebookClick = e => {
    e.preventDefault()

    window.FB.ui(
      {
        method: "share",
        href: props.referralCodePath,
      },
      () => {}
    )
  }

  const onCopyClick = e => {
    referralCodeInput.current.select()
    navigator.clipboard.writeText(props.referralCodePath)

    const emailButton = e.target
    emailButton.textContent = "Copied"
    emailButton.setAttribute("disabled", true)
    setTimeout(() => {
      emailButton.removeAttribute("disabled")
      emailButton.textContent = "Copy"
    }, 1000)
  }

  const renderButtonIcons = (image, url, onClick, title) => {
    const target = url.match(/mailto/) ? "" : "_blank"

    return (
      <a
        href={url}
        target={target}
        className="mr2"
        onClick={onClick}
        role="button"
        title={title}
      >
        <img src={image} text="" />
      </a>
    )
  }

  return (
    <section className="refer_a_friend">
      <h1>Refer a friend</h1>

      <img src={props.illustrationAssetUrl} className="mt1 mb2" />

      <p className="txt-body-3 mb2 mt1--phone">
        Give 10% off and get a credit when someone subscribes using your link.
      </p>

      <input
        type="text"
        readOnly="readonly"
        className="referral-code"
        value={props.referralCodePath}
        ref={referralCodeInput}
        onClick={onReferralCodeClick}
      />

      <div className="actions mt1 mt2--phone">
        {renderButtonIcons(
          props.facebookAssetUrl,
          facebookShareUrl,
          onFacebookClick,
          "Share your code on Facebook"
        )}
        {renderButtonIcons(
          props.emailAssetUrl,
          emailUrl,
          onEmailClick,
          "Share your code via email"
        )}
        {renderButtonIcons(
          props.twitterAssetUrl,
          twitterShareUrl,
          onTwitterClick,
          "Share your code on Twitter"
        )}
        <button
          className="button-tertiary"
          onClick={onCopyClick}
          title="Copy your code"
        >
          Copy
        </button>
      </div>
    </section>
  )
}

export default ReferralCode
