import { Component } from "react"
import PropTypes from "prop-types"
import SmallAppletCard from "shared/components/applet_card/ifttt_next/small_applet_card"
import { connectionShape } from "app/lib/shared_prop_types"
import appletOrConnectionPath from "app/scripts/applet_or_connection_path"

export class StoryAppletCard extends Component {
  //TO DO: Create a story field model that will handle this.
  filterPermissions = () => {
    return this.props.applet.permissions.filter((permission, idx) => {
      return permission.service_slug !== this.props.applet.service_slug
    })
  }

  render() {
    return (
      <li className="my-web-applet-card web-applet-card">
        <SmallAppletCard
          applet={this.props.applet}
          appletPath={appletOrConnectionPath(this.props.applet)}
          overrideToConsumerSite={this.props.linkAppletCardsToConsumerSite}
        />
      </li>
    )
  }
}

StoryAppletCard.propTypes = {
  applet: connectionShape.isRequired,
  term: PropTypes.string,
  position: PropTypes.number.isRequired,
  fieldPosition: PropTypes.number,
  linkAppletCardsToConsumerSite: PropTypes.bool,
}

StoryAppletCard.defaultProps = {
  linkAppletCardsToConsumerSite: false,
}

export default StoryAppletCard
